import axios from "axios";
import { callApiDam } from "./caller";

const apiKey = process.env.REACT_APP_DAM_SERVER_APIKEY

export const getProjects = async (businessId, networkId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/projects` : `/${apiKey}/website-builder/${businessId}/${networkId}/projects`;
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const getProject = async (projectId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/project/${projectId}` : `/${apiKey}/website-builder/project/${projectId}`;
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const deleteProject = async (projectId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/project/${projectId}` : `/${apiKey}/website-builder/project/${projectId}`;
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "DELETE"
    );
    return result;
};

export const createProject = async (businessId, networkId, userId, data) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/${userId}/project` : `/${apiKey}/website-builder/${businessId}/${networkId}/${userId}/project`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const updateProject = async (businessId, networkId, data) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/project/${projectId}` : `/${apiKey}/website-builder/${businessId}/${networkId}/project`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "PUT",
        data
    );
    return result;
};

export const getPages = async (projectId) => {
    if (process.env.REACT_APP_BUILDER_ENV === "production" || process.env.REACT_APP_BUILDER_ENV === "staging") {
        try {
            const result = await axios.get("/data/pages.json")
            return {
                data: result.data,
                success: true,
                message: "Successfully retrieved pages"
            };
        } catch (e) {
            console.log(e)
            return { succes: false, message: "Error fetching pages." };
        }
    } else {
        const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/project/${projectId}/pages` : `/${apiKey}/website-builder/project/${projectId}/pages`
        const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

        const result = await callApiDam(
            url,
            "GET"
        );
        return result;
    }
};

export const getPage = async (pageId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/page/${pageId}` : `/${apiKey}/website-builder/page/${pageId}`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const createPage = async (businessId, networkId, projectId, data) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/project/${projectId}/page` : `/${apiKey}/website-builder/${businessId}/${networkId}/project/${projectId}/page`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const updatePage = async (businessId, networkId, data) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/page` : `/${apiKey}/website-builder/${businessId}/${networkId}/page`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "PUT",
        data
    );
    return result;
};

export const updatePages = async (businessId, networkId, data) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/:BID:/${networkId}/pages` : `/${apiKey}/website-builder/${businessId}/${networkId}/pages`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "PUT",
        data
    );
    return result;
};

export const deletePage = async (pageId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/page/${pageId}` : `/${apiKey}/website-builder/page/${pageId}`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "DELETE"
    );
    return result;
};

export const publish = async (projectId) => {
    const dest = process.env.REACT_APP_ENV === "production" ? `/website-builder/${projectId}/publish` : `/${apiKey}/website-builder/${projectId}/publish`
    const url = process.env.REACT_APP_ENV === "production" ? `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/dam-server?dest=${dest}` : dest;

    const result = await callApiDam(
        url,
        "POST",
        {}
    );
    return result;
};