/* eslint-disable react/button-has-type */
import { useMemo } from 'react';
import useWebsiteData from "../common/hooks/useWebsiteData";
import WebsiteContext from "../common/context/website"
import "./index.css"
import Renderer from "./renderer"

export default function Index() {
    const {
        websiteTheme,
        websitePages,
        websiteFonts,
        websiteDataLoading
    } = useWebsiteData();

    const websiteContextValue = useMemo(() => ({
        websiteTheme,
        websitePages,
        websiteFonts,
        websiteDataLoading
    }), [websiteTheme, websitePages, websiteFonts, websiteDataLoading]);

    return (
        <WebsiteContext.Provider value={websiteContextValue}>
            <Renderer />
        </WebsiteContext.Provider >
    )
}