import type { Config, Data } from "@measured/puck";

import {
  Text,
  TextProps,
  TextWrapper,
  Image,
  ImageProps,
  ImageWrapper,
  Link,
  LinkProps,
  LinkWrapper,
  Video,
  VideoProps,
  VideoWrapper,
  Grid,
  GridProps,
  GridWrapper,
  Buttons,
  ButtonsProps,
  ButtonsWrapper
} from '../puck_components';

import {
  HeroSection_theme_1_variant_1,
  HeroSectionProps_theme_1_variant_1,
  HeroSection_theme_2_variant_1,
  HeroSectionProps_theme_2_variant_1,
  HeroSection_theme_6_variant_1,
  HeroSectionProps_theme_6_variant_1,
  HeroSectionWrapper,
  NewsLetter_theme_1_variant_1,
  NewsLetterProps_theme_1_variant_1,
  NewsLetter_theme_6_variant_1,
  NewsLetterProps_theme_6_variant_1,
  NewsLetterWrapper,
  Contact_theme_1_variant_1,
  ContactProps_theme_1_variant_1,
  Contact_theme_2_variant_1,
  ContactProps_theme_2_variant_1,
  Contact_theme_6_variant_1,
  ContactProps_theme_6_variant_1,
  ContactWrapper,
  Services_theme_1_variant_1,
  ServicesProps_theme_1_variant_1,
  Services_theme_2_variant_1,
  ServicesProps_theme_2_variant_1,
  ServicesWrapper,
  Stats_theme_1_variant_1,
  StatsProps_theme_1_variant_1,
  Stats_theme_2_variant_1,
  StatsProps_theme_2_variant_1,
  StatsWrapper,
  Breadcrumb_theme_1_variant_1,
  BreadcrumbProps_theme_1_variant_1,
  Breadcrumb_theme_2_variant_1,
  BreadcrumbProps_theme_2_variant_1,
  Breadcrumb_theme_6_variant_1,
  BreadcrumbProps_theme_6_variant_1,
  BreadcrumbWrapper,
  Faq_theme_2_variant_1,
  FaqProps_theme_2_variant_1,
  Faq_theme_6_variant_1,
  FaqProps_theme_6_variant_1,
  FaqWrapper,
  Gallery_theme_1_variant_1,
  GalleryProps_theme_1_variant_1,
  Gallery_theme_2_variant_1,
  GalleryProps_theme_2_variant_1,
  Gallery_theme_6_variant_1,
  GalleryProps_theme_6_variant_1,
  GalleryWrapper,
  About_theme_1_variant_1,
  AboutProps_theme_1_variant_1,
  AboutWrapper,
  Team_theme_1_variant_1,
  TeamProps_theme_1_variant_1,
  Team_theme_2_variant_1,
  TeamProps_theme_2_variant_1,
  TeamWrapper,
  Pricing_theme_1_variant_1,
  PricingProps_theme_1_variant_1,
  Pricing_theme_2_variant_1,
  PricingProps_theme_2_variant_1,
  PricingWrapper,
  Overview_theme_1_variant_1,
  OverviewProps_theme_1_variant_1,
  OverviewWrapper,
  Location_theme_1_variant_1,
  LocationProps_theme_1_variant_1,
  Location_theme_2_variant_1,
  LocationProps_theme_2_variant_1,
  LocationWrapper,
  Testimonial_theme_1_variant_1,
  TestimonialProps_theme_1_variant_1,
  Testimonial_theme_2_variant_1,
  TestimonialProps_theme_2_variant_1,
  Testimonial_theme_6_variant_1,
  TestimonialProps_theme_6_variant_1,
  TestimonialWrapper,
  Toast_theme_6_variant_1,
  ToastProps_theme_6_variant_1,
  ToastWrapper
} from '../puck_sections';

import Root, { RootProps } from "./root";

export type Props = {
  Text: TextProps,
  Image: ImageProps,
  Link: LinkProps,
  Video: VideoProps,
  Grid: GridProps,
  Buttons: ButtonsProps,
  HeroSection_theme_1_variant_1: HeroSectionProps_theme_1_variant_1,
  HeroSection_theme_2_variant_1: HeroSectionProps_theme_2_variant_1,
  HeroSection_theme_6_variant_1: HeroSectionProps_theme_6_variant_1,
  NewsLetter_theme_1_variant_1: NewsLetterProps_theme_1_variant_1,
  NewsLetter_theme_6_variant_1: NewsLetterProps_theme_6_variant_1,
  Contact_theme_1_variant_1: ContactProps_theme_1_variant_1,
  Contact_theme_2_variant_1: ContactProps_theme_2_variant_1,
  Contact_theme_6_variant_1: ContactProps_theme_6_variant_1,
  Services_theme_1_variant_1: ServicesProps_theme_1_variant_1,
  Services_theme_2_variant_1: ServicesProps_theme_2_variant_1,
  Stats_theme_1_variant_1: StatsProps_theme_1_variant_1,
  Stats_theme_2_variant_1: StatsProps_theme_2_variant_1,
  Breadcrumb_theme_1_variant_1: BreadcrumbProps_theme_1_variant_1,
  Breadcrumb_theme_2_variant_1: BreadcrumbProps_theme_2_variant_1,
  Breadcrumb_theme_6_variant_1: BreadcrumbProps_theme_6_variant_1,
  About_theme_1_variant_1: AboutProps_theme_1_variant_1,
  Faq_theme_2_variant_1: FaqProps_theme_2_variant_1,
  Faq_theme_6_variant_1: FaqProps_theme_6_variant_1,
  Toast_theme_6_variant_1: ToastProps_theme_6_variant_1,
  Testimonial_theme_1_variant_1: TestimonialProps_theme_1_variant_1,
  Testimonial_theme_2_variant_1: TestimonialProps_theme_2_variant_1,
  Testimonial_theme_6_variant_1: TestimonialProps_theme_6_variant_1,
  Gallery_theme_1_variant_1: GalleryProps_theme_1_variant_1,
  Gallery_theme_2_variant_1: GalleryProps_theme_2_variant_1,
  Gallery_theme_6_variant_1: GalleryProps_theme_6_variant_1,
  Team_theme_1_variant_1: TeamProps_theme_1_variant_1,
  Team_theme_2_variant_1: TeamProps_theme_2_variant_1,
  Pricing_theme_1_variant_1: PricingProps_theme_1_variant_1,
  Pricing_theme_2_variant_1: PricingProps_theme_2_variant_1,
  Overview_theme_1_variant_1: OverviewProps_theme_1_variant_1,
  Location_theme_1_variant_1: LocationProps_theme_1_variant_1,
  Location_theme_2_variant_1: LocationProps_theme_2_variant_1,
};

export type UserConfig = Config<
  Props,
  RootProps,
  "components" | "sections"
>

export const config: UserConfig = {
  categories: {
    components: {
      components: ["Text", "Image", "Link", "Video", "Grid", "Buttons"]
    },
    sections: {
      components: ["HeroSection_theme_1_variant_1", "HeroSection_theme_2_variant_1", "HeroSection_theme_6_variant_1", "NewsLetter_theme_1_variant_1", "NewsLetter_theme_6_variant_1", "Contact_theme_1_variant_1", "Contact_theme_2_variant_1", "Contact_theme_6_variant_1", "Services_theme_1_variant_1", "Services_theme_2_variant_1", "Breadcrumb_theme_1_variant_1", "Breadcrumb_theme_2_variant_1", "Breadcrumb_theme_6_variant_1", "Faq_theme_2_variant_1", "Faq_theme_6_variant_1", "About_theme_1_variant_1", "Gallery_theme_1_variant_1", "Gallery_theme_2_variant_1", "Gallery_theme_6_variant_1", "Team_theme_1_variant_1", "Team_theme_2_variant_1", "Pricing_theme_1_variant_1", "Pricing_theme_2_variant_1", "Overview_theme_1_variant_1", "Stats_theme_1_variant_1", "Stats_theme_2_variant_1", "Location_theme_1_variant_1", "Location_theme_2_variant_1", "Testimonial_theme_1_variant_1", "Testimonial_theme_2_variant_1", "Testimonial_theme_6_variant_1", "Toast_theme_6_variant_1"]
    },
  },
  components: {
    Text,
    Image,
    Link,
    Video,
    Grid,
    Buttons,
    HeroSection_theme_1_variant_1,
    HeroSection_theme_2_variant_1,
    HeroSection_theme_6_variant_1,
    NewsLetter_theme_1_variant_1,
    NewsLetter_theme_6_variant_1,
    Contact_theme_1_variant_1,
    Contact_theme_2_variant_1,
    Contact_theme_6_variant_1,
    Services_theme_1_variant_1,
    Services_theme_2_variant_1,
    About_theme_1_variant_1,
    Breadcrumb_theme_1_variant_1,
    Breadcrumb_theme_2_variant_1,
    Breadcrumb_theme_6_variant_1,
    Stats_theme_1_variant_1,
    Stats_theme_2_variant_1,
    Faq_theme_2_variant_1,
    Faq_theme_6_variant_1,
    Toast_theme_6_variant_1,
    Gallery_theme_1_variant_1,
    Gallery_theme_2_variant_1,
    Gallery_theme_6_variant_1,
    Team_theme_1_variant_1,
    Team_theme_2_variant_1,
    Pricing_theme_1_variant_1,
    Pricing_theme_2_variant_1,
    Overview_theme_1_variant_1,
    Location_theme_1_variant_1,
    Location_theme_2_variant_1,
    Testimonial_theme_1_variant_1,
    Testimonial_theme_2_variant_1,
    Testimonial_theme_6_variant_1,
  },

  root: {
    render: Root,
  },
};


interface Page {
  link: string;
  name: string;
}

export const getInitialData = (pages: Page[]): Record<string, Data> => {
  const data: Record<string, Data> = {};

  pages.forEach(page => {
    data[page.link] = {
      content: [],
      root: {

      },
    };
  });

  return data;
};


const componentMapping: { [key: string]: React.ElementType } = {
  "Text": TextWrapper,
  "Image": ImageWrapper,
  "Link": LinkWrapper,
  "Video": VideoWrapper,
  "Grid": GridWrapper,
  "Buttons": ButtonsWrapper,
  "HeroSection_theme_1_variant_1": HeroSectionWrapper,
  "HeroSection_theme_2_variant_1": HeroSectionWrapper,
  "HeroSection_theme_6_variant_1": HeroSectionWrapper,
  "NewsLetter_theme_1_variant_1": NewsLetterWrapper,
  "NewsLetter_theme_6_variant_1": NewsLetterWrapper,
  "Contact_theme_1_variant_1": ContactWrapper,
  "Contact_theme_2_variant_1": ContactWrapper,
  "Contact_theme_6_variant_1": ContactWrapper,
  "Services_theme_1_variant_1": ServicesWrapper,
  "Services_theme_2_variant_1": ServicesWrapper,
  "Stats_theme_1_variant_1": StatsWrapper,
  "Stats_theme_2_variant_1": StatsWrapper,
  "Breadcrumb_theme_1_variant_1": BreadcrumbWrapper,
  "Breadcrumb_theme_2_variant_1": BreadcrumbWrapper,
  "Breadcrumb_theme_6_variant_1": BreadcrumbWrapper,
  "Gallery_theme_1_variant_1": GalleryWrapper,
  "Gallery_theme_2_variant_1": GalleryWrapper,
  "Gallery_theme_6_variant_1": GalleryWrapper,
  "Team_theme_1_variant_1": TeamWrapper,
  "Team_theme_2_variant_1": TeamWrapper,
  "Pricing_theme_1_variant_1": PricingWrapper,
  "Pricing_theme_2_variant_1": PricingWrapper,
  "Overview_theme_1_variant_1": OverviewWrapper,
  "About_theme_1_variant_1": AboutWrapper,
  "Location_theme_1_variant_1": LocationWrapper,
  "Location_theme_2_variant_1": LocationWrapper,
  "Toast_theme_6_variant_1": ToastWrapper,
  "Faq_theme_2_variant_1": FaqWrapper,
  "Faq_theme_6_variant_1": FaqWrapper,
  "Testimonial_theme_1_variant_1": TestimonialWrapper,
  "Testimonial_theme_2_variant_1": TestimonialWrapper,
  "Testimonial_theme_6_variant_1": TestimonialWrapper,
};

interface ComponentRendererProps {
  componentKey: string;
}

export const RenderComponent: React.FC<ComponentRendererProps & {
  children: React.ReactNode,
  showText: Boolean,
  showIcon: Boolean
}> = ({ children, componentKey, showText, showIcon }) => {
  const Component = componentMapping[componentKey] || null;

  return Component ? <Component showText={showText} showIcon={showIcon}>{children}</Component> : <div>{children}</div>;
};