import React from "react";
import ReactDOM from "react-dom";
import Renderer from "./Renderer"
import * as serviceWorker from "./serviceWorker";
import "./tailwind.output.css";
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams
} from "react-router-dom";  

const RenderPage = () => {
  const history = useHistory();
  const { page } = useParams();

  // Redirect to /:page
  React.useEffect(() => {
    history.push(`/${page}`);
  }, [history, page]);

  return null; // or a loading indicator if needed
}

ReactDOM.render(
  <AppRouter>
    <Switch>

      <Route exact
        path="/"
      >
        <Redirect to="/home" />
      </Route>

      <Route exact
        path="/:page"
        render={() =>
          <Renderer />
        }
      />

    </Switch>
  </AppRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
