import React from 'react';
import { IoSearch } from 'react-icons/io5'

export const groupByBaseName = (items, baseNameGetter) => {
    const groupedItems = {};

    items.forEach((item, globalIndex) => {
        const baseName = baseNameGetter(item);
        if (!groupedItems[baseName]) {
            groupedItems[baseName] = [];
        }
        groupedItems[baseName].push({ item, globalIndex });
    });

    return groupedItems;
};

const Search = ({ searchQuery, setSearchQuery, placeholder, onSubmit = null }) => {
    return (
        <form onSubmit={onSubmit ? onSubmit : undefined} className="tw-w-full tw-mx-auto">
            <label className="tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-sr-only tw-dark:text-white">Search</label>
            <div className="tw-relative">
                <div className="tw-absolute tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-ps-3 tw-pointer-events-none dark:tw-text-white">
                    <IoSearch size={14} />
                </div>
                <input
                    className="tw-block tw-w-full tw-p-4 tw-px-8 tw-text-sm tw-border tw-rounded-lg tw-bg-cardBackground dark:tw-bg-cardBackgroundDark dark:tw-placeholder-white/70 dark:tw-text-white"
                    placeholder={placeholder}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                {onSubmit ?
                    <button type='submit' disabled={!searchQuery} className="tw-absolute tw-right-2 tw-p-2 tw-text-primary tw-bg-primaryContainer dark:tw-bg-primaryContainerDark disabled:tw-opacity-80 tw-rounded-lg tw-top-1/2 -tw-translate-y-1/2 focus:tw-outline-none hover:-tw-translate-x-[1px]">
                        Search
                    </button>
                    :
                    <>

                    </>
                }
            </div>
        </form>
    );
};

export default Search;