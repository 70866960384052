import { useState, useRef, ChangeEvent } from "react";
import UtilityModal from "../../hooks/useModal"
import {
    CardText,
} from "reactstrap";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { motion } from 'framer-motion'
import Search from "./Search";

const UploadImage = ({ setShowUploader, url, setUrl }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const uploadInputRef = useRef(null);

    const [server, setServer] = useState("uzeli")
    const [searchQuery, setSearchQuery] = useState("")

    const uzeliImages: any[] = [
        'https://picsum.photos/200/300?random=1',
        'https://picsum.photos/200/300?random=2',
        'https://picsum.photos/200/300?random=3',
        'https://picsum.photos/200/300?random=4',
        'https://picsum.photos/200/300?random=5',
        'https://picsum.photos/200/300?random=6',
        'https://picsum.photos/200/300?random=7',
        'https://picsum.photos/200/300?random=8',
        'https://picsum.photos/200/300?random=9',
        'https://picsum.photos/200/300?random=10',
        'https://picsum.photos/200/300?random=11',
        'https://picsum.photos/200/300?random=12',
        'https://picsum.photos/200/300?random=1',
        'https://picsum.photos/200/300?random=2',
        'https://picsum.photos/200/300?random=3',
        'https://picsum.photos/200/300?random=4',
        'https://picsum.photos/200/300?random=5',
        'https://picsum.photos/200/300?random=6',
        'https://picsum.photos/200/300?random=7',
        'https://picsum.photos/200/300?random=8',
        'https://picsum.photos/200/300?random=9',
        'https://picsum.photos/200/300?random=10',
        'https://picsum.photos/200/300?random=11',
        'https://picsum.photos/200/300?random=12',
        'https://picsum.photos/200/300?random=1',
        'https://picsum.photos/200/300?random=2',
        'https://picsum.photos/200/300?random=3',
        'https://picsum.photos/200/300?random=4',
        'https://picsum.photos/200/300?random=5',
        'https://picsum.photos/200/300?random=6',
        'https://picsum.photos/200/300?random=7',
        'https://picsum.photos/200/300?random=8',
        'https://picsum.photos/200/300?random=9',
        'https://picsum.photos/200/300?random=10',
        'https://picsum.photos/200/300?random=11',
        'https://picsum.photos/200/300?random=12',
    ]

    const [images, setImages] = useState<any[]>(uzeliImages);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUploading(true);
        try {
            const formData = new FormData();
            files.forEach(({ file }) => {
                formData.append('files', file);
            });

            const response = await axios.post('UPLOAD_URL', formData);
            setImages(response.data.urls);
            setFiles([])
        } catch (err) {
            setError('Failed to upload images. Please try again.');
            setTimeout(() => setError(""), 2000);
        } finally {
            setIsUploading(false);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        const newFiles = Array.from(selectedFiles).map(file => {
            const preview = URL.createObjectURL(file);
            return { file, preview };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    const handleImageClick = () => {
        uploadInputRef.current.click();
    };

    const onDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => {
            const preview = URL.createObjectURL(file);
            return { file, preview };
        });
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    const handleDeleteFile = (index: number) => {
        setFiles(files => files.filter((_, i) => i !== index));
    };

    const fetchUnsplash = async () => {
        const access_key = "K3RLyoa2pOlKRNbp2I9aDdqCy34cbekWqehHK7zwjJQ"
        const data = await fetch(
            `https://api.unsplash.com/search/photos?page=1&query=${searchQuery}&client_id=${access_key}`
        );
        const dataJ = await data.json();
        const result = dataJ.results;
        setImages(result)
    };

    const handleServerChange = (server) => {
        setServer(server)
        if (server === "uzeli") setImages(uzeliImages);
        else {
            setImages([]);
            if (searchQuery) {
                fetchUnsplash();
            }
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': [] } });

    return (
        <UtilityModal fullBox showModal={true} setShowModal={setShowUploader}>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-5 tw-h-full tw-w-full">
                <div className="tw-h-fit md:tw-h-full tw-flex tw-flex-col tw-gap-4">
                    <div className="tw-h-fit tw-w-fit tw-mx-auto tw-rounded-lg tw-shadow dark:tw-bg-backgroundDark tw-items-center">
                        <form onSubmit={handleSubmit} className="tw-p-4">
                            <div
                                {...getRootProps()}
                                className={`tw-w-[220px] tw-h-[190px] md:tw-w-[270px] md:tw-h-[230px] tw-p-6 tw-mb-4 tw-border-dashed tw-border-2 tw-border-primary dark:tw-bg-backgroundDark tw-rounded-lg tw-items-center tw-mx-auto tw-text-center tw-cursor-pointer`}
                                onClick={handleImageClick}
                            >
                                <input
                                    {...getInputProps()}
                                    type="file"
                                    className="tw-hidden"
                                    accept="image/*"
                                    ref={uploadInputRef}
                                    onChange={handleFileChange}
                                />
                                <label className="tw-cursor-pointer tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-2 tw-justify-center">
                                    <span>
                                        <FiUpload size={25} />
                                    </span>
                                    <div>
                                        <h5 className="tw-text-xl tw-font-bold tw-tracking-tight">
                                            Upload pictures
                                        </h5>
                                        <p className="tw-font-normal tw-text-sm">
                                            Drag and drop images here, or click to upload.
                                        </p>
                                        <p className="tw-font-normal tw-text-sm">
                                            Picture should be in <b >JPG, PNG, or JPEG</b> format.
                                        </p>
                                    </div>
                                </label>
                            </div>

                            <div className="tw-w-[220px] md:tw-w-[270px] tw-mx-auto tw-flex tw-flex-col tw-gap-1 tw-items-center tw-justify-center">
                                {error && (
                                    <CardText style={{ color: "red" }}>
                                        {error}
                                    </CardText>
                                )}
                                <button disabled={!files.length || isUploading} className="tw-w-full dark:tw-text-white enabled:dark:tw-bg-primaryDark focus:tw-outline-none tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-flex tw-items-center tw-justify-center tw-mr-2 tw-mb-2">
                                    {isUploading ? <CircularProgress className="dark:tw-text-white" size={20} /> : null}
                                    <span className="tw-text-center tw-ml-2">Upload</span>
                                </button>
                            </div>
                        </form>
                    </div>

                    {files.length ? (
                        <div className="tw-w-full tw-h-fit md:tw-h-full tw-p-4 tw-overflow-x-auto tw-overflow-y-hidden md:tw-overflow-x-hidden md:tw-overflow-y-auto tw-shadow tw-rounded-lg tw-flex md:tw-flex-col tw-gap-3 tw-outline-outline dark:tw-outline-outlineDark dark:tw-bg-cardBackgroundDark tw-outline-1">
                            {files.map((fileWrapper, index) => (
                                <div key={index} className="tw-relative tw-flex-shrink-0 tw-rounded-lg tw-shadow-lg tw-w-[220px] tw-h-[190px] md:tw-w-[270px] md:tw-h-[230px] tw-mx-auto md:tw-mx-0 tw-overflow-hidden">
                                    <img src={fileWrapper.preview} alt="" className="tw-w-full tw-h-full tw-object-cover" />
                                    <button onClick={() => handleDeleteFile(index)} className="tw-absolute tw-top-1 tw-right-1 tw-bg-primary tw-text-white tw-rounded tw-p-1 hover:tw-translate-x-[1px] hover:-tw-translate-y-[1px]">
                                        <FaTrash size={16} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) :
                        <></>
                    }
                </div>

                <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4">
                    <ul className="tw-flex tw-flex-wrap tw-text-sm tw-font-medium tw-text-center tw-text-onBackground tw-border-b tw-border-outline dark:tw-border-outlineDark dark:tw-text-onBackgroundDark">
                        <li className="tw-me-2">
                            <span onClick={() => handleServerChange("uzeli")} className={`tw-inline-block tw-p-4 tw-rounded-t-lg ${server === "uzeli" ? "tw-text-primary tw-bg-primaryContainer dark:tw-bg-primaryContainerDark dark:tw-text-primaryDark" : "hover:tw-text-onPrimaryContainer hover:tw-bg-secondaryContainer dark:hover:tw-bg-secondaryContainerDark dark:hover:tw-text-onPrimaryContainerDark tw-cursor-pointer"}`}>Uzeli</span>
                        </li>
                        <li className="tw-me-2">
                            <span onClick={() => handleServerChange("unsplash")} className={`tw-inline-block tw-p-4 tw-rounded-t-lg ${server === "unsplash" ? "tw-text-primary tw-bg-primaryContainer dark:tw-bg-primaryContainerDark dark:tw-text-primaryDark" : "hover:tw-text-onPrimaryContainer hover:tw-bg-secondaryContainer dark:hover:tw-bg-secondaryContainerDark dark:hover:tw-text-onPrimaryContainerDark tw-cursor-pointer"}`}>Unsplash</span>
                        </li>
                    </ul>

                    {server === "unsplash" &&
                        <div>
                            <Search
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                placeholder="Search images..."
                                onSubmit={fetchUnsplash}
                            />
                        </div>
                    }

                    <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4 tw-h-full tw-overflow-y-auto">
                        {images.map((image, index) => (
                            <motion.button
                                onClick={() => {
                                    if (server === "unsplash")
                                        setUrl(image.urls.regular);
                                    else
                                        setUrl(image);

                                    setShowUploader(false);
                                }}
                                whileHover={{ scale: 0.95 }}
                                key={index}
                                className="tw-overflow-hidden tw-rounded-lg tw-h-[190px] md:tw-h-[230px] tw-shadow-lg focus:tw-outline-none tw-relative tw-p-0">
                                <img src={server === "unsplash" ? image.urls.regular : image} alt="" className="tw-w-full tw-h-full tw-object-cover" />
                            </motion.button>
                        ))}
                    </div>
                </div>
            </div>
        </UtilityModal >
    )
}

export default UploadImage