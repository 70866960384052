import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { FaQuoteLeft } from "react-icons/fa";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type TestimonialProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    testimony: {
        description: {
            text: string;
            typography: textFieldsType
        };
        customer: {
            name: {
                text: string;
                typography: textFieldsType
            };
            location: {
                text: string;
                typography: textFieldsType
            };
            src: string;
        };
    };
    backgroundImages: {
        url_1: string,
        url_2: string,
        url_3: string,
    }
};

export const Testimonial: ComponentConfig<TestimonialProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        testimony: {
            type: "object",
            objectFields: {
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                customer: {
                    type: "object",
                    objectFields: {
                        name: {
                            type: "object",
                            objectFields: {
                                text: { type: "textarea", label: "Text" },
                                typography: {
                                    type: "object",
                                    objectFields: textFields,
                                    label: "Typography"
                                },
                            },
                            label: "Description"
                        },
                        location: {
                            type: "object",
                            objectFields: {
                                text: { type: "textarea", label: "Text" },
                                typography: {
                                    type: "object",
                                    objectFields: textFields,
                                    label: "Typography"
                                },
                            },
                            label: "Description"
                        },
                        src: { ...ImageField, label: "Src" },
                    }
                },
            },
            label: "Testimony"
        },
        backgroundImages: {
            type: "object",
            objectFields: {
                url_1: { ...ImageField, label: "Url 1" },
                url_2: { ...ImageField, label: "Url 2" },
                url_3: { ...ImageField, label: "Url 3" },
            },
            label: "Background Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "It'S NOT YOU, IT'S YOUR EYEBROWS",
            typography: createDefaultProps("text", {
                fontSize: 25,
                fontWeight: 600,
                lineHeight: 1.1,
            }),
        },
        description: {
            text: "Great hair doesn’t happen by chance, it happens by appointment!",
            typography: createDefaultProps("text", {
                fontSize: 15,
                fontWeight: 500,
                lineHeight: 1.2,
                color: { hex: themes.theme_1.colors.neutral }
            }),
        },
        testimony: {
            description: {
                text: "I've been to many spas, but the massage I received here was exceptional. The therapist was skilled and attentive, and I left feeling completely relaxed and tension-free.",
                typography: createDefaultProps("text", {
                    fontSize: 20,
                    fontWeight: 500,
                    lineHeight: 1.3,
                    style: "italic",
                }),
            },
            customer: {
                src: "https://img.freepik.com/premium-photo/passport-photo-portrait-asian-smiling-woman_264197-7671.jpg",
                name: {
                    text: "Denilia Clarke",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                location: {
                    text: "California, USA",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        style: "italic",
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            }
        },
        backgroundImages: {
            url_1: "https://images.fresha.com/locations/location-profile-images/946641/1208694/15afd873-4648-4026-80cb-8aaf7d3e5d96.jpg?class=venue-gallery-large",
            url_2: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
            url_3: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type == "testimonyDescription") {
                updatedData.testimony.description.text = e.target.textContent;
            } else if (type == "testimonyCustomerName") {
                updatedData.testimony.customer.name.text = e.target.textContent;
            } else if (type == "testimonyCustomerLocation") {
                updatedData.testimony.customer.location.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Testimonial_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>

                    <div style={{ backgroundColor: theme.colors.primary }} className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 tw-w-full tw-mx-auto tw-shadow-md tw-relative tw-mt-[80px] tw-p-[30px] tw-text-center tw-z-[2]">
                        <div style={{ backgroundColor: "white", color: theme.colors.secondary }} className="tw-p-4 tw-rounded-full tw-absolute -tw-top-[30px] tw-w-fit tw-mx-auto">
                            <FaQuoteLeft size={24} />
                        </div>

                        <div style={{ outline: "0.5px solid white" }} className="tw-px-[30px] tw-py-[90px]">
                            <EditableText classes="tw-max-w-screen-lg tw-mx-auto" handleChange={(e) => handleChange(e, "testimonyDescription")} data={data.testimony.description.typography}>
                                {data.testimony.description.text}
                            </EditableText>

                            <div className="tw-flex tw-flex-col tw-gap-2">
                                <EditableText handleChange={(e) => handleChange(e, "testimonyCustomerName")} data={data.testimony.customer.name.typography}>
                                    {data.testimony.customer.name.text}
                                </EditableText>
                                <EditableText handleChange={(e) => handleChange(e, "testimonyCustomerLocation")} data={data.testimony.customer.location.typography}>
                                    {data.testimony.customer.location.text}
                                </EditableText>
                            </div>
                        </div>

                        <div className="tw-w-[320px] tw-h-[230px] tw-absolute -tw-top-[20px] -tw-left-[40px]">
                            <img
                                src={data.backgroundImages.url_2}
                                alt=""
                                className="tw-w-full tw-h-full tw-object-contain"
                            />
                        </div>

                        <div className="tw-w-[320px] tw-h-[230px] tw-absolute -tw-bottom-[20px] -tw-right-[40px]">
                            <img
                                src={data.backgroundImages.url_3}
                                alt=""
                                className="tw-w-full tw-h-full tw-object-contain"
                            />
                        </div>
                    </div>

                    <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-grid tw-z-[1]">
                        <div className="tw-relative">
                            <div
                                style={{
                                    backgroundImage: `url("${data.backgroundImages.url_1}")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    opacity: 0.15
                                }}
                            ></div>

                            <div style={{
                                backgroundImage: theme.colors.primary,
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                left: 0,
                            }}>
                            </div>
                        </div>

                        <div>
                        </div>
                    </div>

                </div>
            </Wrapper >
        );
    },
};

export const TestimonialWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Testimonial">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Testimonial</div>
        }
    </div>;
};