import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableLink, EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";

export type TeamProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    teammates: {
        label: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        url: string;
        facebook_url: string;
        linkedin_url: string;
        instagram_url: string;
        twitter_url: string;
    }[];
    link: (globalCommonFieldsType & {
        text: string;
        url: string;
        typography: textFieldsType
    });
};

export const Team: ComponentConfig<TeamProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        teammates: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                url: { ...ImageField, label: "Url" },
                facebook_url: { type: "text", label: "Facebook" },
                linkedin_url: { type: "text", label: "LinkedIn" },
                instagram_url: { type: "text", label: "Instagram" },
                twitter_url: { type: "text", label: "Twitter" },
            },
            defaultItemProps: {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Maria Williams",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Hair Stylist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary },
                        font: { name: "Montserrat" },
                    }),
                },
                facebook_url: "",
                linkedin_url: "",
                instagram_url: "",
                twitter_url: ""
            },
            label: "Team"
        },
        link: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                url: { type: "text", label: "Url" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Phone"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Life is more beautiful when you meet the right hairdresser",
            typography: createDefaultProps("text", {
                fontSize: 25,
                fontWeight: 600,
                lineHeight: 1.1,
            }),
        },
        teammates: [
            {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Maria Williams",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Hair Stylist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary },
                        font: { name: "Montserrat" },
                    }),
                },
                facebook_url: "",
                linkedin_url: "",
                instagram_url: "",
                twitter_url: ""
            },
            {
                url: "https://static.vecteezy.com/system/resources/previews/004/675/721/non_2x/middle-aged-woman-having-a-head-massage-in-a-beauty-salon-photo.jpg",
                label: {
                    text: "Blair Bliss",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Massage Specialist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary },
                        font: { name: "Montserrat" },
                    }),
                },
                facebook_url: "",
                linkedin_url: "",
                instagram_url: "",
                twitter_url: ""
            },
            {
                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNOqVWA3K-nJXKi9XfncBfxI-WIk7HwsaIZutHHAn9Q&s",
                label: {
                    text: "Tini Tiny",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Threading Specialist",
                    typography: createDefaultProps("text", {
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary },
                        font: { name: "Montserrat" },
                    }),
                },
                facebook_url: "",
                linkedin_url: "",
                instagram_url: "",
                twitter_url: ""
            },
        ],
        link: {
            text: "View All Members",
            url: "#",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 16, top: 12, right: 16, bottom: 12, } },
                backgroundColor: { hex: themes.theme_2.colors.secondary }
            })
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type == "teammateTitle") {
                updatedData.teammates[identifier].label.text = e.target.textContent;
            } else if (type == "teammateDescription") {
                updatedData.teammates[identifier].description.text = e.target.textContent;
            } else if (type == "link") {
                updatedData.link.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Team_theme_2_variant_1" }
        };

        const responsive = {
            breakpoint3: {
                breakpoint: { max: 3000, min: 1490 },
                items: 4,
                slidesToSlide: 4
            },
            breakpoint2: {
                breakpoint: { max: 1490, min: 1024 },
                items: 3,
                slidesToSlide: 3
            },
            breakpoint1: {
                breakpoint: { max: 1024, min: 620 },
                items: 2,
                slidesToSlide: 2
            },
            breakpoint0: {
                breakpoint: { max: 620, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center tw-justify-center tw-mb-5`}>
                        <DropZone zone="team_theme_2_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <div className="tw-flex tw-justify-between tw-items-center tw-gap-10 tw-text-center md:tw-text-left">
                            <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                                {data.title.text}
                            </EditableText>
                            <div className={`tw-hidden md:tw-flex tw-w-fit tw-h-fit`}>
                                <EditableLink styles={{ padding: generateSpacingCSS(data.link.spacing.padding), margin: generateSpacingCSS(data.link.spacing.margin), backgroundColor: data.link.backgroundColor.hex, ...generateBorderCSS(data.link.border) }} classes="tw-inline-flex tw-justify-center tw-items-center" href={data.link.url} handleChange={(e) => handleChange(e, "link")} data={{ typography: data.link.typography }}>
                                    {data.link.text}
                                </EditableLink>
                            </div>
                        </div>

                        <div style={{ backgroundColor: theme.colors.secondary }} className="tw-h-[4px] tw-w-[60px] tw-rounded tw-my-2 tw-mx-auto md:tw-mx-0"></div>

                        <DropZone zone="team_theme_2_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-w-full">
                        <Carousel
                            responsive={responsive}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            infinite={true}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                            arrows={false}
                            className=""
                        >
                            {data.teammates.map((teammate, i) =>
                                <div key={i} className="tw-shadow tw-rounded-lg tw-overflow-hidden tw-h-[270px] tw-w-[270px] md:tw-h-[310px] md:tw-w-[310px] tw-relative tw-group tw-mx-auto tw-cursor-pointer">
                                    <div style={{ backgroundImage: theme.colors.primary }} className="tw-h-full tw-w-full tw-p-6 tw-absolute group-hover:tw-opacity-100 tw-opacity-0">
                                        <div style={{ border: "0.5px solid white", borderBottom: "none" }} className="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">

                                            <EditableText handleChange={(e) => handleChange(e, "teammateTitle", i)} data={teammate.label.typography}>
                                                {teammate.label.text}
                                            </EditableText>
                                            <EditableText handleChange={(e) => handleChange(e, "teammateDescription", i)} data={teammate.description.typography}>
                                                {teammate.description.text}
                                            </EditableText>

                                            <div className="tw-flex tw-items-center tw-gap-2 tw-mt-2">
                                                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-text-white tw-flex tw-items-center tw-justify-center" style={{ backgroundColor: theme.colors.secondary }}>
                                                    <FaFacebookF />
                                                </span>
                                                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-text-white tw-flex tw-items-center tw-justify-center" style={{ backgroundColor: theme.colors.secondary }}>
                                                    <FaTwitter />
                                                </span>
                                                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-text-white tw-flex tw-items-center tw-justify-center" style={{ backgroundColor: theme.colors.secondary }}>
                                                    <FaLinkedinIn />
                                                </span>
                                                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-text-white tw-flex tw-items-center tw-justify-center" style={{ backgroundColor: theme.colors.secondary }}>
                                                    <FaInstagram />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="group-hover:tw-opacity-[0.15]"
                                        style={{
                                            backgroundImage: `url("${teammate.url}")`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            bottom: 0,
                                            left: 0
                                        }}
                                    ></div>
                                </div>
                            )}
                        </Carousel>
                    </div>

                </div>
            </Wrapper >
        );
    },
};

export const TeamWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Team">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Team</div>
        }
    </div>;
};