import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type TestimonialProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    testimonies: {
        label: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        customer: {
            name: {
                text: string;
                typography: textFieldsType
            };
            bio: {
                text: string;
                typography: textFieldsType
            };
            src: string;
        };
    }[];
};

export const Testimonial: ComponentConfig<TestimonialProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        testimonies: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                customer: {
                    type: "object",
                    objectFields: {
                        name: {
                            type: "object",
                            objectFields: {
                                text: { type: "textarea", label: "Text" },
                                typography: {
                                    type: "object",
                                    objectFields: textFields,
                                    label: "Typography"
                                },
                            },
                            label: "Name"
                        },
                        bio: {
                            type: "object",
                            objectFields: {
                                text: { type: "textarea", label: "Text" },
                                typography: {
                                    type: "object",
                                    objectFields: textFields,
                                    label: "Typography"
                                },
                            },
                            label: "bio"
                        },
                        src: { ...ImageField, label: "Src" },
                    },
                    label: "Customer"
                },
            },
            defaultItemProps: {
                label: {
                    text: "Ultimate relaxation experience!",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Visiting this salon was like stepping into a paradise of tranquility. The ambiance, the service, everything was just perfect. I left feeling completely rejuvenated.",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                customer: {
                    name: {
                        text: "Sophie Anderson",
                        typography: createDefaultProps("text", {
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    bio: {
                        text: "Regular Client",
                        typography: createDefaultProps("text", {
                            color: { hex: themes.theme_6.colors.secondary },
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    src: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png"
                }
            },
            label: "Testimonies"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Testimonial",
            typography: createDefaultProps("text", {
                fontSize: 25,
                fontWeight: 600,
                lineHeight: 1.1,
            }),
        },
        testimonies: [
            {
                label: {
                    text: "Ultimate relaxation experience!",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Visiting this salon was like stepping into a paradise of tranquility. The ambiance, the service, everything was just perfect. I left feeling completely rejuvenated.",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                customer: {
                    name: {
                        text: "Sophie Anderson",
                        typography: createDefaultProps("text", {
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    bio: {
                        text: "Regular Client",
                        typography: createDefaultProps("text", {
                            color: { hex: themes.theme_6.colors.secondary },
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    src: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png"
                }
            },
            {
                label: {
                    text: "Fantastic service and attention to detail",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "From the moment I walked in, I was treated like royalty. The staff paid meticulous attention to my preferences and ensured I had a delightful spa experience.",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                customer: {
                    name: {
                        text: "Michael Thompson",
                        typography: createDefaultProps("text", {
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    bio: {
                        text: "First-Time Visitor",
                        typography: createDefaultProps("text", {
                            color: { hex: themes.theme_6.colors.secondary },
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    src: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                }
            },
            {
                label: {
                    text: "Best massage I've ever had!",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "I've been to many spas, but the massage I received here was exceptional. The therapist was skilled and attentive, and I left feeling completely relaxed and tension-free.",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                customer: {
                    name: {
                        text: "Emma Carter",
                        typography: createDefaultProps("text", {
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    bio: {
                        text: "Spa Enthusiast",
                        typography: createDefaultProps("text", {
                            color: { hex: themes.theme_6.colors.secondary },
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    src: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                }
            },
            {
                label: {
                    text: "A sanctuary of beauty and serenity",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "This salon is my go-to place for all my beauty needs. Whether it's a haircut, a facial, or a manicure, I always leave feeling pampered and refreshed.",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.1,
                    }),
                },
                customer: {
                    name: {
                        text: "David Roberts",
                        typography: createDefaultProps("text", {
                            fontSize: 20,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    bio: {
                        text: "Regular Client",
                        typography: createDefaultProps("text", {
                            color: { hex: themes.theme_6.colors.secondary },
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 1.1,
                        }),
                    },
                    src: "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png"
                }
            }
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 25, right: 0, bottom: 25, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type == "testimonyDescription") {
                updatedData.testimonies[identifier].description.text = e.target.textContent;
            } else if (type == "testimonyCustomerName") {
                updatedData.testimonies[identifier].customer.name.text = e.target.textContent;
            } else if (type == "testimonyCustomerBio") {
                updatedData.testimonies[identifier].customer.bio.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Testimonial_theme_6_variant_1" }
        };

        const theme = websiteTheme.name === "theme_6" ? websiteTheme.content : themes.theme_6
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`tw-px-4 lg:tw-px-6 tw-py-5`}>
                    <div className="text-center tw-mb-6">
                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                    </div>
                    <div className="tw-grid md:tw-grid-cols-2">
                        {data.testimonies.map((testimony, i) => (
                            <figure key={i} className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-8 tw-text-center tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg">
                                <blockquote className="tw-max-w-2xl tw-mx-auto tw-mb-4 tw-text-gray-500 lg:tw-mb-8">
                                    <EditableText handleChange={(e) => handleChange(e, "testimonyLabel", i)} data={testimony.label.typography}>
                                        {testimony.label.text}
                                    </EditableText>
                                    <EditableText classes="tw-my-4" handleChange={(e) => handleChange(e, "testimonyDescription", i)} data={testimony.description.typography}>
                                        {testimony.description.text}
                                    </EditableText>
                                </blockquote>
                                <figcaption className="tw-flex tw-items-center tw-justify-center">
                                    <img className="tw-rounded-full tw-w-9 tw-h-9" src={testimony.customer.src} alt="profile picture" />
                                    <div className="tw-space-y-0.5 tw-font-medium tw-text-left rtl:tw-text-right tw-ml-3">
                                        <EditableText handleChange={(e) => handleChange(e, "testimonyDescription", i)} data={testimony.customer.name.typography}>
                                            {testimony.customer.name.text}
                                        </EditableText>
                                        <EditableText handleChange={(e) => handleChange(e, "testimonyDescription", i)} data={testimony.customer.bio.typography}>
                                            {testimony.customer.bio.text}
                                        </EditableText>
                                    </div>
                                </figcaption>
                            </figure>
                        ))}
                    </div>
                </div>
            </Wrapper >

        );
    },
};