import React from "react";
import { ComponentConfig } from "@measured/puck";
import { FaRegImage } from "react-icons/fa";
import { Tooltip } from "../utils/Tooltip";
import Wrapper from "../utils/Wrapper";
import { useWebsiteContext } from "../../context/website";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../helpers'
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type SizeFieldsType, sizeFields } from "../utils/CommonFields";
import { ImageField } from "../puck_fields";

export type ImageProps = globalCommonFieldsType & {
    src?: string;
    fit: "fill" | "contain" | "cover" | "none" | "scale-down";
    size: SizeFieldsType
    alt?: string;
};

export const Image: ComponentConfig<ImageProps> = {
    fields: {
        src: { ...ImageField, label: "Url" },
        fit: {
            type: "select",
            options: [
                { label: "none", value: "none" },
                { label: "fill", value: "fill" },
                { label: "contain", value: "contain" },
                { label: "cover", value: "cover" },
                { label: "scale-down", value: "scale-down" },
            ],
            label: "Fit"
        },
        size: {
            type: "object",
            objectFields: sizeFields,
            label: "Size"
        },
        alt: { type: "text", label: "Alt" },
        ...globalCommonFields
    },
    defaultProps: {
        src: "",
        fit: "contain",
        size: {
            height: 100,
            width: 200,
        },
        alt: "",
        ...createDefaultProps("globalCommon")
    },
    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const theme = websiteTheme.content;
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex;

        return (
            <Wrapper styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div
                    style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin), ...generateBorderCSS(data.border) }}>
                    <div className="tw-w-full">
                        <img
                            height={data.size.height}
                            width={data.size.width}
                            alt={data.alt}
                            style={{
                                objectFit: data.fit,
                                height: data.size.height ? `${data.size.height}px` : undefined,
                                width: data.size.width ? `${data.size.width}px` : undefined,
                            }}
                            src={data.src ? data.src : "https://imgv3.fotor.com/images/homepage-feature-card/upload-image_2023-04-11-023334_kxuh.jpg"}
                        />
                    </div>
                </div>
            </Wrapper>
        );
    },
};

export const ImageWrapper: React.FC<{
    children: React.ReactNode;
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2" : "tw-px-2 tw-py-1"} tw-border-transparent tw-rounded tw-shadow-md tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-1 tw-bg-background dark:tw-bg-backgroundDark`}>
        {showIcon &&
            (
                showText ?
                    <FaRegImage size={22} />
                    :
                    <Tooltip title="Image">
                        <span>
                            <FaRegImage size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Image</div>
        }
    </div>;
};