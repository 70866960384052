import React, { useEffect } from 'react'
import { useWebsiteContext } from '../../context/website'
import { IoMdClose } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import ModalWrapper from "./Modal";

export default function BookModal({ setShowBookModal, showBookModal = true }) {

    const { websiteTheme } = useWebsiteContext();

    const locations = [
        {
            name: "Pretty Eyebrows & Salon - Waterford",
            address: "4195 Highland Road"
        },
        {
            name: "Pretty Brow Art - Westland",
            address: "35000. W. Warren Rd."
        },
        {
            name: "Pretty Brows - Novi",
            address: "43426 W Oaks II"
        },
        {
            name: "Pretty Brow Art - Westland",
            address: "35000. W. Warren Rd."
        },
        {
            name: "Pretty Brows - Novi",
            address: "43426 W Oaks II"
        },
    ]

    const contentStyle = {
        backgroundColor: websiteTheme.content.colors.background,
        color: websiteTheme.content.colors.text
    };

    const headerStyle = {
        backgroundColor: websiteTheme.content.colors.background,
        color: websiteTheme.content.colors.text
    };

    const bodyStyle = {
        backgroundColor: websiteTheme.content.colors.background,
        color: websiteTheme.content.colors.text
    };

    return (
        <ModalWrapper showModal={showBookModal} setShowModal={setShowBookModal} contentStyle={contentStyle}>
            <ModalWrapper.Header style={headerStyle}>
                <div
                    style={{ backgroundColor: websiteTheme.content.colors.background, color: websiteTheme.content.colors.secondary }}
                    className="tw-p-3 tw-rounded-full tw-left-[50%] -tw-translate-x-1/2 -tw-top-8 tw-absolute"
                >
                    <MdLocationOn size={40} />
                </div>
                <div
                    style={{ color: websiteTheme.content.colors.secondary }}
                    onClick={() => setShowBookModal(false)}
                    className="tw-cursor-pointer tw-p-1 tw-rounded hover:tw-shadow-md"
                >
                    <IoMdClose size={20} />
                </div>
            </ModalWrapper.Header>
            <ModalWrapper.Body style={bodyStyle}>
                <div className="tw-h-[350px] tw-w-[360px] tw-font-medium tw-flex tw-flex-col tw-gap-4 tw-p-4">
                    <div className="tw-text-lg">Choose the location you want to book an appointment with.</div>
                    <ul className="tw-overflow-y-scroll tw-flex tw-flex-col tw-space-y-4 tw-h-full">
                        {locations.map((location, i) => (
                            <li key={i} className="tw-border-b-2 tw-border-dashed tw-py-2 tw-cursor-pointer">
                                <div>{location.name}</div>
                                <div style={{ color: websiteTheme.content.colors.neutral }} className="tw-font-normal">
                                    {location.address}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </ModalWrapper.Body>
        </ModalWrapper>
    )
}
