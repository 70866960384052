import { useState } from "react"
import BookModal from "./BookModal"

const BookButton = ({ label, styles = {}, classes = "", singleLocation = false }) => {
    const [showBookModal, setShowBookModal] = useState(false);

    return (
        <>
            <button onClick={() => setShowBookModal(true)} className={`focus:tw-outline-none tw-whitespace-nowrap tw-font-normal ${classes}`} style={styles}>
                {label}
            </button>

            {showBookModal &&
                <BookModal setShowBookModal={setShowBookModal} />
            }
        </>
    )
}

export default BookButton