import React, { ReactNode, useEffect } from "react";
import { DefaultRootProps } from "@measured/puck";
import { useWebsiteContext } from "../../context/website";
import { UnloadFonts } from "../../lib/FontLoader";
import { Helmet } from 'react-helmet';

const importHeader = (theme) => {
  const module = require("../page_sections/Header");
  return module["Header_" + theme]
}

const importFooter = (theme) => {
  const module = require("../page_sections/Footer");
  return module["Footer_" + theme]
}

export type RootProps = {
  children: ReactNode;
  title: string;
} & DefaultRootProps;

function Root({ children, editMode }: RootProps) {

  // theme
  const { websiteTheme, websitePages, websiteFonts } = useWebsiteContext();

  // useEffect(() => {
  //   // LoadFonts(fonts);

  //   return () => {
  //     UnloadFonts(fonts);
  //   }
  // }, [])

  const Header = importHeader(websiteTheme.header.name);
  const Footer = importFooter(websiteTheme.footer.name);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"></link>
        {websiteFonts.map((font) => {
          const fontUrl = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(font)}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap`;
          return <link key={font} id={font} href={fontUrl} rel="stylesheet" crossOrigin="anonymous" />;
        })}
      </Helmet>

      <div dir="ltr" style={{ fontFamily: `"${websiteTheme.global.fonts[0]}", serif`, backgroundColor: websiteTheme.global.colors.background, color: websiteTheme.global.colors.text }}>
        <Header editMode={editMode} pages={websitePages} theme={websiteTheme.header} />
        <main style={{ fontFamily: `"${websiteTheme.content.fonts[0]}", serif`, backgroundColor: websiteTheme.content.colors.background, color: websiteTheme.content.colors.text }}>
          {children}
        </main>
        <Footer editMode={editMode} pages={websitePages} theme={websiteTheme.footer} />
      </div>
    </>
  );
}

export default Root;