import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { config, getInitialData } from "../puck/config";
import { Data, resolveAllData } from "@measured/puck";
import { useWebsiteContext } from "../context/website";

export const useData = ({ path }: { path: string }) => {
  const { websitePages, websiteDataLoading } = useWebsiteContext();
  const initialData = useMemo(() => getInitialData(websitePages), [websitePages]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData[path]);
  const [resolvedData, setResolvedData] = useState(data);

  useEffect(() => {
    if (!websiteDataLoading) {
      const page = websitePages.find(page => page.link === path);
      const actualData = page?.data ? JSON.parse(page.data) : initialData[path] || undefined;
      setData(actualData);
    }
  }, [websitePages, path, websiteDataLoading]);

  useEffect(() => {
    const ResolvePuckData = async () => {
      try {
        const resolved = await resolveAllData(data, config);
        setResolvedData(resolved);
      } catch (e) {
        setResolvedData(undefined);
      }
      setLoading(false);
    }
    if (!websiteDataLoading) {
      ResolvePuckData();
    }
  }, [websiteDataLoading, data]);

  return {
    data,
    resolvedData,
    key: path,
    loading
  };
};