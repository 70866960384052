import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const ModalWrapper = ({ showModal, setShowModal, children, contentStyle }) => {
    useEffect(() => {
        if (showModal) {
            document.body.classList.add('tw-overflow-hidden');
        } else {
            document.body.classList.remove('tw-overflow-hidden');
        }

        // Clean up on unmount
        return () => {
            document.body.classList.remove('tw-overflow-hidden');
        };
    }, [showModal]);

    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const modalVariants = {
        hidden: { y: '-10vh', opacity: 0 },
        visible: { y: '0', opacity: 1, transition: { delay: 0.2 } },
        exit: { y: '100vh', opacity: 0 },
    };

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            setShowModal(false);
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={backdropVariants}
            className="tw-flex tw-overflow-hidden tw-fixed tw-top-0 tw-right-0 tw-z-50 tw-justify-center tw-items-center tw-w-screen tw-h-screen tw-bg-black tw-bg-opacity-60"
            onClick={handleBackdropClick}
        >
            <motion.div variants={modalVariants} className="tw-relative tw-w-fit tw-h-fit">
                {/* Modal content */}
                <div className="tw-relative tw-rounded-lg tw-shadow" style={contentStyle}>
                    {/* Modal header */}
                    <div className="tw-relative tw-w-full tw-flex tw-justify-end tw-p-2">
                        {React.Children.toArray(children).find(child => child.type === Header)}
                    </div>

                    {/* Modal body */}
                    <div className="tw-relative tw-p-2">
                        {React.Children.toArray(children).find(child => child.type === Body)}
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

const Header = ({ children, style }) => <div style={style}>{children}</div>;
const Body = ({ children, style }) => <div style={style}>{children}</div>;

ModalWrapper.Header = Header;
ModalWrapper.Body = Body;

export default ModalWrapper;