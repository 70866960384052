import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { FaPlusCircle } from "react-icons/fa";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type ServicesProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    services: {
        label: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        url: string;
    }[];
    backgroundImages: {
        url_1: string,
        url_2: string,
    }
};

export const Services: ComponentConfig<ServicesProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        services: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                url: { ...ImageField, label: "Url" },
            },
            defaultItemProps: {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Facial",
                    typography: createDefaultProps("text", {
                        fontSize: 30,
                        fontWeight: 500,
                        lineHeight: 1.1,
                        font: { name: "Roboto" }
                    }),
                },
                description: {
                    text: "Indulge in our signature facial treatment that will leave your skin glowing and refreshed. Our expert estheticians will customize the experience to your skin's unique needs, using premium products and techniques.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            label: "Services"
        },
        backgroundImages: {
            type: "object",
            objectFields: {
                url_1: { ...ImageField, label: "Url 1" },
                url_2: { ...ImageField, label: "Url 2" },
            },
            label: "Background Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "It'S NOT YOU, IT'S YOUR EYEBROWS",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 15,
                fontWeight: 600,
                lineHeight: 1.05,
            }),
        },
        description: {
            text: "Friends dont let friends have bad eyebrows !!",
            typography: createDefaultProps("text", {
                fontSize: 35,
                fontWeight: 500,
                lineHeight: 1.3,
            }),
        },
        services: [
            {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Revitalizing Facial",
                    typography: createDefaultProps("text", {
                        fontSize: 30,
                        fontWeight: 500,
                        lineHeight: 1.1,
                        font: { name: "Roboto" }
                    }),
                },
                description: {
                    text: "Indulge in our signature facial treatment that will leave your skin glowing and refreshed. Our expert estheticians will customize the experience to your skin's unique needs, using premium products and techniques.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                url: "https://static.vecteezy.com/system/resources/previews/004/675/721/non_2x/middle-aged-woman-having-a-head-massage-in-a-beauty-salon-photo.jpg",
                label: {
                    text: "Relaxing Massage",
                    typography: createDefaultProps("text", {
                        fontSize: 30,
                        fontWeight: 500,
                        lineHeight: 1.1,
                        font: { name: "Roboto" }
                    }),
                },
                description: {
                    text: "Unwind and rejuvenate with our therapeutic massage services. Our skilled massage therapists will tailor the pressure and techniques to your preferences, promoting overall relaxation.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNOqVWA3K-nJXKi9XfncBfxI-WIk7HwsaIZutHHAn9Q&s",
                label: {
                    text: "Luxury Manicure & Pedicure",
                    typography: createDefaultProps("text", {
                        fontSize: 30,
                        fontWeight: 500,
                        lineHeight: 1.1,
                        font: { name: "Roboto" }
                    }),
                },
                description: {
                    text: "Treat your hands and feet to the ultimate pampering experience. Our luxurious manicure and pedicure services leave you with beautifully groomed and polished nails.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
        ],
        backgroundImages: {
            url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
            url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type == "serviceTitle") {
                updatedData.services[identifier].label.text = e.target.textContent;
            } else if (type == "serviceDescription") {
                updatedData.services[identifier].description.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Services_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center`}>
                        <DropZone zone="services_theme_1_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                        <EditableText handleChange={(e) => handleChange(e, "description")} data={data.description.typography}>
                            {data.description.text}
                        </EditableText>


                        <DropZone zone="services_theme_1_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-items-center tw-justify-center tw-gap-5 tw-mt-10 tw-w-fit tw-mx-auto">
                        {data.services.map((service, i) =>
                            <div className="tw-w-[280px] tw-h-[450px] md:tw-w-[350px] md:tw-h-[480px] xl:tw-w-[420px] xl:tw-h-[550px] tw-relative">
                                <img
                                    src={service.url}
                                    alt={service.label.text}
                                    className="tw-w-full tw-h-full tw-object-cover"
                                />

                                <div className="tw-absolute tw-bottom-2 md:tw-bottom-3 xl:tw-bottom-5 tw-w-full tw-flex tw-items-center tw-justify-center tw-text-center">
                                    <div className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-5 tw-mx-auto tw-bg-white tw-p-6 tw-w-[260px] tw-h-[280px] md:tw-w-[320px] md:tw-h-[240px] xl:tw-w-[360px] xl:tw-h-[250px]">
                                        <EditableText handleChange={(e) => handleChange(e, "serviceTitle", i)} data={service.label.typography}>
                                            {service.label.text}
                                        </EditableText>
                                        <EditableText handleChange={(e) => handleChange(e, "serviceDescription", i)} data={service.description.typography}>
                                            {service.description.text}
                                        </EditableText>
                                        <div className="tw-flex tw-gap-2 tw-justify-center tw-text-md tw-font-['Roboto']">
                                            <span><FaPlusCircle size={16} /></span>
                                            <span>READ MORE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

                <div className="tw-w-[320px] tw-h-[230px] tw-absolute -tw-top-[60px] -tw-left-[60px]">
                    <img
                        src={data.backgroundImages.url_1}
                        alt=""
                        className="tw-w-full tw-h-full tw-object-contain"
                    />
                </div>

                <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-top-0 -tw-right-[30px]">
                    <img
                        src={data.backgroundImages.url_2}
                        alt=""
                        className="tw-w-full tw-h-full tw-object-contain"
                    />
                </div>
            </Wrapper>
        );
    },
};

export const ServicesWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Services">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Services</div>
        }
    </div>;
};