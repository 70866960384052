import { ReactNode } from "react";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../../lib/utils";
import BookButton from "../../utils/BookButton";
import Wrapper from "../../utils/Wrapper";

const FooterLink = ({ children, href, classes }: { children: ReactNode; href: string; classes?: string }) => {
  return (
    <li className={`tw-pb-2 ${classes}`}>
      <Link
        to={href}
        className="tw-no-underline"
      >
        {children}
      </Link>
    </li>
  );
};

const FooterList = ({
  children,
  title,
  classes
}: {
  children: ReactNode;
  title: string;
  classes?: string;
}) => {
  return (
    <div className={`${classes || ""} tw-leading-5`}>
      <h3 className="tw-mb-6 tw-text-2xl tw-font-semibold tw-uppercase">
        {title}
      </h3>
      <ul className="tw-list-none tw-font-medium tw-flex tw-flex-col tw-gap-1 tw-text-xl">
        {children}
      </ul>
    </div>
  );
};

const Footer = ({ children, editMode, theme, pages }: { children: ReactNode, editMode: boolean, theme: any, pages: any }) => {
  const footerStyle: React.CSSProperties = {
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    fontFamily: `"${theme.fonts[0]}", serif`
  };

  const backgroundImages = {
    url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
    url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
  }

  return (
    <footer style={footerStyle} className="tw-relative tw-overflow-hidden">
      <Wrapper noStyles className="tw-py-6 lg:tw-py-8 tw-relative tw-z-10">
        <div className="tw-grid tw-gap-8 md:tw-gap-16 tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto">
          <FooterList title="About Us">
            <div style={{ color: theme.colors.neutral }} className="tw-font-['Roboto'] tw-font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam magna urna, laoreet sit amet urna sed, pharetra condimentum ex. Vestibulum nisi quam, luctus sed massa eleifend, dapibus pellentesque velit. Proin gravida dolor non quam vestibulum, in sodales erat aliquam.
            </div>
            <div className={`tw-inline-flex tw-mt-5 tw-w-full`}>
              <BookButton label="MAKE APPOINTMENT" styles={{ backgroundColor: theme.colors.secondary }} classes="tw-py-3 tw-px-4 tw-font-semibold tw-text-lg" />
            </div>
          </FooterList>
          <FooterList classes="tw-flex tw-flex-col tw-items-center mt-1" title="Contact Us">
            <div className="tw-font-normal tw-flex tw-flex-col tw-gap-5 tw-items-center">
              <div>
                Phone: (910) 491-1926
              </div>
              <div>
                Email: info@salon.com
              </div>
              <div>
                Office: Fayetteville NC 28311
              </div>
              <div className={`sm:tw-inline-flex tw-mt-5 tw-grid tw-gap-3`}>
                <a style={{ borderColor: theme.colors.secondary }} className="tw-py-3 tw-px-4 tw-inline-flex tw-justify-center tw-items-center tw-gap-x-2 tw-font-semibold tw-border tw-border-transparent tw-disabled:opacity-50 tw-disabled:pointer-events-none tw-text-lg" href="#">
                  Get Direction
                </a>
              </div>
            </div>
          </FooterList>
          <FooterList title="Important Links">
            {
              pages.map((page, i) =>
                <FooterLink key={i} classes="tw-font-['Roboto'] tw-font-normal" href={getAbsolutePath(page.link, editMode)}>{page.name}</FooterLink>
              )
            }
          </FooterList>
        </div>

        <div className="tw-text-base tw-text-center tw-mt-[100px] tw-font-['Roboto']">© Copyright 2023 <Link to={getAbsolutePath("home", editMode)} className="hover:tw-underline"></Link>. All Rights Reserved.
        </div>
      </Wrapper>

      <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 -tw-left-[60px] tw-hidden md:tw-block tw-z-[5]">
        <img
          src={backgroundImages.url_1}
          alt=""
          className="tw-w-full tw-h-full tw-object-contain"
        />
      </div>

      <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 -tw-right-[30px] tw-z-[5]">
        <img
          src={backgroundImages.url_2}
          alt=""
          className="tw-w-full tw-h-full tw-object-contain"
        />
      </div>

    </footer>
  );
};

export { Footer };