import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type PricingProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    services: {
        label: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        price: {
            text: string;
            typography: textFieldsType
        };
        url: string;
    }[];
    backgroundImage: string,
};

export const Pricing: ComponentConfig<PricingProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        services: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                url: { ...ImageField, label: "Url" },
                price: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Price"
                },
            },
            label: "Services"
        },
        backgroundImage: {
            ...ImageField,
            label: "Background Image"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Invest in your hair, it’s the crown you never take off!",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 30,
                fontWeight: 500,
                lineHeight: 1.3,
            }),
        },
        services: [
            {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Cleansing and Massaging",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
                description: {
                    text: "It’s a 45 minutes service",
                    typography: createDefaultProps("text", {
                        font: { name: "Montserrat" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                price: {
                    text: "$75",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary }
                    }),
                },
            },
            {
                url: "https://static.vecteezy.com/system/resources/previews/004/675/721/non_2x/middle-aged-woman-having-a-head-massage-in-a-beauty-salon-photo.jpg",
                label: {
                    text: "Threading",
                    typography: createDefaultProps("text", {
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
                description: {
                    text: "It’s a 45 minutes service",
                    typography: createDefaultProps("text", {
                        font: { name: "Montserrat" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                price: {
                    text: "$100",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary }
                    }),
                },
            },
            {
                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNOqVWA3K-nJXKi9XfncBfxI-WIk7HwsaIZutHHAn9Q&s",
                label: {
                    text: "Hairdressing",
                    typography: createDefaultProps("text", {
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
                description: {
                    text: "It’s an hour service",
                    typography: createDefaultProps("text", {
                        font: { name: "Montserrat" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                price: {
                    text: "$120",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.secondary }
                    }),
                },
            },
        ],
        backgroundImages: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png",
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type == "serviceTitle") {
                updatedData.services[identifier].label.text = e.target.textContent;
            } else if (type == "serviceDescription") {
                updatedData.services[identifier].description.text = e.target.textContent;
            } else if (type == "servicePrice") {
                updatedData.services[identifier].price.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Pricing_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className={`tw-flex tw-flex-col tw-justify-center tw-z-10 tw-gap-4 tw-text-center tw-max-w-screen-md tw-mx-auto`}>
                        <DropZone zone="pricing_theme_2_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>

                        <div style={{ backgroundColor: theme.colors.secondary }} className="tw-h-[4px] tw-w-[60px] tw-rounded tw-mx-auto tw-mt-2"></div>

                        <DropZone zone="pricing_theme_2_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-flex tw-flex-col tw-gap-16 tw-mt-16 tw-max-w-screen-lg tw-mx-auto tw-px-8">
                        {data.services.map((service, i) =>
                            <div style={{ backgroundColor: theme.colors.primary }} className={`tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-relative tw-p-10 md:tw-p-8 tw-w-fit tw-mx-auto md:tw-w-full ${i % 2 != 0 ? "tw-translate-x-8" : "-tw-translate-x-8"} `}>
                                <div className="tw-h-[160px] tw-w-[200px] md:tw-h-[110px] md:tw-w-[120px] tw-relative">
                                    <img
                                        src={service.url}
                                        alt=""
                                        className="tw-w-full tw-h-full tw-object-cover"
                                    />
                                </div>
                                <div className="tw-mt-1 tw-flex tw-flex-col tw-gap-4 tw-w-full">
                                    <div className="tw-flex tw-items-center tw-w-[200px] md:tw-w-full">
                                        <EditableText classes="tw-w-fit" handleChange={(e) => handleChange(e, "serviceTitle", i)} data={service.label.typography}>
                                            {`${service.label.text}`}
                                        </EditableText>
                                        <div className="tw-border-white tw-border-b-2 tw-border-dotted tw-w-full tw-mx-1">
                                        </div>
                                        <EditableText classes="tw-w-fit" handleChange={(e) => handleChange(e, "servicePrice", i)} data={service.price.typography}>
                                            {`${service.price.text}`}
                                        </EditableText>
                                    </div>
                                    <EditableText classes="" handleChange={(e) => handleChange(e, "serviceDescription", i)} data={service.description.typography}>
                                        {service.description.text}
                                    </EditableText>
                                </div>

                                <div className="tw-w-[100px] tw-h-[100px] tw-absolute tw-bottom-[2px] tw-right-[2px] tw-hidden md:tw-block">
                                    <img
                                        src={data.backgroundImage}
                                        alt=""
                                        className="tw-w-full tw-h-full tw-object-contain"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </Wrapper >
        );
    },
};

export const PricingWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Pricing">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Pricing</div>
        }
    </div>;
};