import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type StatsProps = globalCommonFieldsType & {
    items: {
        label: {
            text: string;
            typography: textFieldsType
        };
        description: {
            text: string;
            typography: textFieldsType
        };
        quantity: {
            text: string;
            typography: textFieldsType
        };
    }[];
    backgroundImage: string;
};

export const Stats: ComponentConfig<StatsProps> = {
    fields: {
        items: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                description: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Description"
                },
                quantity: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Quantity"
                },
            },
            defaultItemProps: {
                label: {
                    text: "#",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                quantity: {
                    text: "10",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            label: "Items"
        },
        backgroundImage: {
            ...ImageField,
            label: "Background Image"
        },
        ...globalCommonFields
    },
    defaultProps: {
        items: [
            {
                label: {
                    text: "Years of Experience",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "We have 50 years of combined experience in the beauty and wellness industry.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                quantity: {
                    text: "50",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                label: {
                    text: "Services Offered",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "We offer over 30 different services, including haircuts, massages, facials, and more.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                quantity: {
                    text: "30+",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                label: {
                    text: "Satisfied Customers",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "We have served over 10,000 satisfied customers who keep coming back for our exceptional services.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                quantity: {
                    text: "10000+",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            },
            {
                label: {
                    text: "Expert Staff",
                    typography: createDefaultProps("text", {
                        fontSize: 25,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                description: {
                    text: "Our team consists of over 20 expert stylists, therapists, and beauticians dedicated to providing top-notch services.",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: 1.2,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
                quantity: {
                    text: "20+",
                    typography: createDefaultProps("text", {
                        font: { name: "Roboto" },
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                        color: { hex: themes.theme_1.colors.neutral }
                    }),
                },
            }
        ],
        backgroundImage: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type == "itemTitle") {
                updatedData.items[identifier].label.text = e.target.textContent;
            } else if (type == "itemDescription") {
                updatedData.items[identifier].description.text = e.target.textContent;
            } else if (type == "itemQuantity") {
                updatedData.items[identifier].quantity.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Stats_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>

                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-5 tw-w-fit tw-mt-10 tw-mx-auto">
                        {data.items.map((item, i) =>
                            <div style={{ backgroundColor: theme.colors.background }} className="tw-w-[280px] tw-h-[290px] md:tw-w-[310px] md:tw-h-[315px] tw-flex tw-flex-col tw-p-4 tw-justify-center tw-items-center tw-gap-5 tw-text-center">
                                <EditableText handleChange={(e) => handleChange(e, "itemQuantity", i)} data={item.quantity.typography}>
                                    {item.quantity.text}
                                </EditableText>
                                <EditableText handleChange={(e) => handleChange(e, "itemTitle", i)} data={item.label.typography}>
                                    {item.label.text}
                                </EditableText>
                                <EditableText handleChange={(e) => handleChange(e, "itemDescription", i)} data={item.description.typography}>
                                    {item.description.text}
                                </EditableText>
                            </div>
                        )}
                    </div>

                </div>

                <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-top-0 -tw-right-[30px]">
                    <img
                        src={data.backgroundImage}
                        alt=""
                        className="tw-w-full tw-h-full tw-object-contain"
                    />
                </div>
            </Wrapper >
        );
    },
};

export const StatsWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Stats">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Stats</div>
        }
    </div>;
};