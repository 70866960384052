import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableLink, EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type AboutProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    overview: {
        text: string;
        typography: textFieldsType
    };
    link: (globalCommonFieldsType & {
        text: string;
        href: string;
        typography: textFieldsType
    });
    backgroundImages: {
        url_1: string,
        url_2: string,
    }
};

export const About: ComponentConfig<AboutProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        overview: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "About"
        },
        link: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                href: { type: "text", label: "Href" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Link"
        },
        backgroundImages: {
            type: "object",
            objectFields: {
                url_1: { ...ImageField, label: "Url 1" },
                url_2: { ...ImageField, label: "Url 2" },
            },
            label: "Background Images"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "It'S NOT YOU, IT'S YOUR EYEBROWS",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 15,
                fontWeight: 600,
                lineHeight: 1.05,
            }),
        },
        description: {
            text: "Where we love to exceed your expectations.",
            typography: createDefaultProps("text", {
                fontSize: 35,
                fontWeight: 500,
                lineHeight: 1.3,
            }),
        },
        overview: {
            text: "Indulge in ultimate relaxation at our luxurious spa and salon, where expert therapists pamper you with rejuvenating treatments and personalized beauty services, leaving you refreshed, revitalized, and radiant.",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                color: { hex: themes.theme_1.colors.neutral },
                fontSize: 20,
                fontWeight: 500,
                lineHeight: 1.4,
            }),
        },
        link: {
            text: "Learn More",
            href: "#",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 16, top: 12, right: 16, bottom: 12, } },
                backgroundColor: { hex: themes.theme_2.colors.secondary }
            })
        },
        backgroundImages: {
            url_1: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png",
            url_2: "https://images.fresha.com/locations/location-profile-images/946641/1208694/15afd873-4648-4026-80cb-8aaf7d3e5d96.jpg?class=venue-gallery-large",
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 90, right: 0, bottom: 110, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type === 'overview') {
                updatedData.overview.text = e.target.textContent;
            } else if (type === 'link') {
                updatedData.link.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "About_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`lg:tw-grid-cols-2 tw-grid tw-justify-center tw-items-center tw-gap-10 lg:tw-gap-3 tw-text-black`}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-5 tw-text-center lg:tw-text-left`}>
                        <DropZone zone="about_theme_1_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                        <EditableText handleChange={(e) => handleChange(e, "description")} data={data.description.typography}>
                            {data.description.text}
                        </EditableText>

                        <EditableText handleChange={(e) => handleChange(e, "overview")} data={data.overview.typography}>
                            {data.overview.text}
                        </EditableText>

                        <div className={`tw-justify-center lg:tw-justify-start tw-flex tw-mt-5 tw-w-full`}>
                            <EditableLink styles={{ padding: generateSpacingCSS(data.link.spacing.padding), margin: generateSpacingCSS(data.link.spacing.margin), backgroundColor: data.link.backgroundColor.hex, ...generateBorderCSS(data.link.border) }} handleChange={(e) => handleChange(e, "link")} href={data.link.href} data={{ typography: data.link.typography }}>
                                {data.link.text}
                            </EditableLink>
                        </div>

                        <DropZone zone="about_theme_1_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 -tw-left-[60px] tw-hidden md:tw-block">
                        <img
                            src={data.backgroundImages.url_1}
                            alt=""
                            className="tw-w-full tw-h-full tw-object-contain"
                        />
                    </div>

                    <div className="tw-h-[450px] tw-hidden lg:tw-block">
                        <img
                            src={data.backgroundImages.url_2}
                            alt=""
                            className="tw-w-full tw-h-full tw-object-cover"
                        />
                    </div>

                </div>
            </Wrapper>
        );
    },
};