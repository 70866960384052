import { ReactNode } from "react";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../../lib/utils";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import BookButton from "../../utils/BookButton";
import Wrapper from "../../utils/Wrapper";

const FooterLink = ({ children, href, classes }: { children: ReactNode; href: string; classes?: string }) => {
  return (
    <li style={{ fontFamily: "Montserrat" }} className={`tw-pb-2 ${classes}`}>
      <Link
        to={href}
        className="tw-no-underline"
      >
        {children}
      </Link>
    </li>
  );
};

const FooterList = ({
  children,
  title,
  classes,
  theme
}: {
  children: ReactNode;
  title: string;
  classes?: string;
  theme: any;
}) => {

  return (
    <div className={`${classes} tw-leading-5 tw-z-[10]`}>
      <h3 className="tw-mb-4 tw-text-2xl tw-font-semibold tw-uppercase">
        {title}
      </h3>

      <div style={{ backgroundColor: theme.colors.secondary }} className="tw-mb-4 tw-h-[2px] tw-w-[50px] tw-rounded"></div>

      <ul className="tw-list-none tw-font-medium tw-flex tw-flex-col tw-gap-1 tw-text-xl">
        {children}
      </ul>
    </div>
  );
};

const Footer = ({ children, editMode, theme, pages }: { children: ReactNode, editMode: boolean, theme: any, pages: any }) => {
  const footerStyle: React.CSSProperties = {
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    fontFamily: `"${theme.fonts[0]}", serif`
  };

  const backgroundImages = {
    url_1: "https://cdn.pixabay.com/photo/2017/11/03/11/33/rose-2914093_1280.png",
    url_2: "https://cdn.pixabay.com/photo/2017/11/26/16/22/rose-2979256_1280.png"
  }

  return (
    <footer style={footerStyle} className="tw-relative tw-overflow-hidden">
      <Wrapper noStyles className="tw-py-6 lg:tw-py-8 tw-relative tw-z-10">
        <div className="tw-grid tw-gap-8 md:tw-gap-16 tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto">
          <FooterList theme={theme} title="About Us">
            <div style={{ color: theme.colors.neutral, fontFamily: "Montserrat" }} className=" tw-font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam magna urna, laoreet sit amet urna sed, pharetra condimentum ex. Vestibulum nisi quam, luctus sed massa eleifend, dapibus pellentesque velit. Proin gravida dolor non quam vestibulum, in sodales erat aliquam.
            </div>
            <div className={`tw-inline-flex tw-mt-5 tw-w-full`}>
              <BookButton label="MAKE APPOINTMENT" styles={{ backgroundColor: theme.colors.secondary }} classes="tw-py-3 tw-px-4 tw-font-semibold tw-text-lg" />
            </div>
          </FooterList>
          <FooterList theme={theme} title="Quick Links">
            {
              pages.map((page, i) =>
                <FooterLink key={i} classes="tw-font-normal" href={getAbsolutePath(page.link, editMode)}>{page.name}</FooterLink>
              )
            }
          </FooterList>

          <div className="tw-flex tw-flex-col tw-gap-8">
            <FooterList theme={theme} title="Subscription">
              <div className={`tw-flex`}>
                <label htmlFor="email-address" className="tw-sr-only">
                  Email address
                </label>
                <input
                  style={{ backgroundColor: theme.colors.primary, fontFamily: "Montserrat", color: theme.colors.text }}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="tw-min-w-0 tw-flex-auto tw-border-0 tw-p-3.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-white/10 focus:tw-outline-none"
                  placeholder="Enter your email"
                />
                <button
                  style={{ backgroundColor: theme.colors.secondary }}
                  type="submit"
                  className="tw-flex-none tw-py-3.5 tw-px-4 tw-font-semibold tw-shadow-sm tw-text-white"
                >
                  Sign up
                </button>
              </div>
            </FooterList>

            <FooterList theme={theme} title="Follow us">
              <div className="tw-flex tw-items-center tw-gap-2">
                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaFacebookF />
                </span>
                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaTwitter />
                </span>
                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaLinkedinIn />
                </span>
                <span className="tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center" style={{ color: theme.colors.secondary }}>
                  <FaInstagram />
                </span>
              </div>
            </FooterList>
          </div>
        </div>

        <div style={{ fontFamily: "Montserrat" }} className="tw-text-base tw-text-center tw-mt-[100px] tw-relative tw-z-[10]">© Copyright 2023 - <Link to={getAbsolutePath("home", editMode)} className="hover:tw-underline" style={{ color: theme.colors.secondary }}>Riya's Just Threading</Link>. All Rights Reserved.
        </div>
      </Wrapper >

      <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 -tw-left-[60px] tw-hidden md:tw-block tw-z-[5]">
        <img
          src={backgroundImages.url_1}
          alt=""
          className="tw-w-full tw-h-full tw-object-contain"
        />
      </div>

      <div className="tw-w-[320px] tw-h-[230px] tw-absolute tw-bottom-0 -tw-right-[30px] tw-z-[5]">
        <img
          src={backgroundImages.url_2}
          alt=""
          className="tw-w-full tw-h-full tw-object-contain"
        />
      </div>

    </footer >
  );
};

export { Footer };