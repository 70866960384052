/* eslint-disable radix */
/* eslint-disable no-undef */
import { themes } from "../theme"
import { useState } from "react";
import { getPages } from "../api";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import { toast } from 'react-toastify';

// Importing pages.json only if in production mode
let initialWebsitePages = [];
let initialWebsiteTheme = {
    global: themes.theme_2,
    header: themes.theme_2,
    content: themes.theme_2,
    footer: themes.theme_2
};
let initialWebsiteFonts = Object.values(themes).reduce((acc, theme) => acc.concat(theme.fonts), []);

const useWebsiteData = () => {
    const [websiteDataLoading, setWebsiteDataLoading] = useState(true);

    // current website theme
    const [websiteTheme, setWebsiteTheme] = useState(initialWebsiteTheme);

    // pages in website
    const [websitePages, setWebsitePages] = useState(initialWebsitePages);

    // current website fonts
    const [websiteFonts, setWebsiteFonts] = useState(initialWebsiteFonts);

    const { projectId } = useParams();

    useEffect(() => {
        const fetchPages = async () => {
            const pagesResponse = await getPages(projectId);
            if (pagesResponse?.success) {
                setWebsitePages(pagesResponse.data.pages);

                // website data i.e. project data
                const builderData = JSON.parse(pagesResponse?.data.builder_data.data);
                setWebsiteTheme(builderData.websiteTheme);
                setWebsiteFonts(builderData.websiteFonts);
            } else {
                toast?.error("Problem fetching pages.");
            }
            setWebsiteDataLoading(false);
        };

        fetchPages();
    }, [projectId]);

    return {
        websiteDataLoading,
        setWebsiteDataLoading,
        websiteTheme,
        setWebsiteTheme,
        websitePages,
        setWebsitePages,
        websiteFonts,
        setWebsiteFonts
    };
};

export default useWebsiteData;
