import { ReactNode } from "react";
import { Link } from 'react-router-dom'
import { getAbsolutePath } from "../../../lib/utils";
import Wrapper from "../../utils/Wrapper";

const FooterLink = ({ children, href }: { children: ReactNode; href: string }) => {
  return (
    <li className="tw-pb-2">
      <Link
        href={href}
        className="tw-no-underline tw-text-sm"
      >
        {children}
      </Link>
    </li>
  );
};

const FooterList = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <div>
      <h3 className="tw-mb-6 tw-text-base tw-font-semibold tw-uppercase">
        {title}
      </h3>
      <ul className="tw-list-none tw-font-medium tw-flex tw-flex-col tw-gap-1">
        {children}
      </ul>
    </div>
  );
};

const Footer = ({ children, editMode, theme }: { children: ReactNode, editMode: boolean, theme: any }) => {
  const footerStyle: React.CSSProperties = {
    backgroundColor: theme.colors.primary,
    color: theme.colors.text,
    fontFamily: `"${theme.fonts[0]}", serif`
  };

  return (
    <footer style={footerStyle}>
      <Wrapper noStyles className="tw-py-6 lg:tw-py-8">
        <div className="md:tw-flex md:tw-justify-between">
          <div className="tw-mb-8 md:tw-mb-0">
            <Link to={getAbsolutePath("home", editMode)} className="tw-flex tw-items-center">
              <img src="https://growthzilla.com/temp/uzeli-logo.png" className="tw-h-8 tw-me-3" alt="Uzeli Logo" />
              <span className="tw-self-center tw-text-2xl tw-font-semibold tw-whitespace-nowrap">Uzeli</span>
            </Link>
          </div>
          <div className="tw-grid tw-gap-8 md:tw-gap-16 tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto">
            <FooterList title="Section A">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
            <FooterList title="Section B">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
            <FooterList title="Section C">
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
              <FooterLink href="#">Label</FooterLink>
            </FooterList>
          </div>
        </div>
        <hr style={{ borderColor: theme.colors.secondary }} className="tw-my-6 tw-border-gray-200 sm:tw-mx-auto lg:tw-my-8" />
        <div className="tw-text-sm tw-text-center">© 2023 <Link to={getAbsolutePath("home", editMode)} className="hover:tw-underline">Uzeli</Link>. All Rights Reserved.
        </div>
      </Wrapper>
    </footer>
  );
};

export { Footer };