import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { BsTrophyFill } from "react-icons/bs";
import { Tooltip } from "../../../utils/Tooltip";
import { EditableText } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import BookButton from "../../../utils/BookButton";

export type ServicesProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    services: {
        label: {
            text: string;
            typography: textFieldsType
        };
        price: {
            text: string;
            typography: textFieldsType
        };
        url: string;
    }[];
};

export const Services: ComponentConfig<ServicesProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        services: {
            type: "array",
            arrayFields: {
                label: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Title"
                },
                url: { ...ImageField, label: "Url" },
                price: {
                    type: "object",
                    objectFields: {
                        text: { type: "textarea", label: "Text" },
                        typography: {
                            type: "object",
                            objectFields: textFields,
                            label: "Typography"
                        },
                    },
                    label: "Price"
                },
            },
            defaultItemProps: {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Cleansing and Massaging",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                price: {
                    text: "$75",
                    typography: createDefaultProps("text", {
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
            },
            label: "Services"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "Friends dont let friends have bad eyebrows !!",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 40,
                fontWeight: 500,
                lineHeight: 1.3,
            }),
        },
        services: [
            {
                url: "https://media.istockphoto.com/id/1367037346/video/4k-video-footage-of-a-young-woman-enjoying-a-relaxing-facial-at-a-spa.jpg?s=640x640&k=20&c=ah61W-9YfaP7VXg3WeRaR991aaOokTIpzzP7o7GExHc=",
                label: {
                    text: "Cleansing and Massaging",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                price: {
                    text: "$75",
                    typography: createDefaultProps("text", {
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
            },
            {
                url: "https://static.vecteezy.com/system/resources/previews/004/675/721/non_2x/middle-aged-woman-having-a-head-massage-in-a-beauty-salon-photo.jpg",
                label: {
                    text: "Threading",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                price: {
                    text: "$100",
                    typography: createDefaultProps("text", {
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
            },
            {
                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNOqVWA3K-nJXKi9XfncBfxI-WIk7HwsaIZutHHAn9Q&s",
                label: {
                    text: "Hairdressing",
                    typography: createDefaultProps("text", {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: 1.1,
                    }),
                },
                price: {
                    text: "$120",
                    typography: createDefaultProps("text", {
                        fontSize: 50,
                        fontWeight: 600,
                        lineHeight: 1.3,
                    }),
                },
            },
        ],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 100, right: 0, bottom: 100, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type == "serviceTitle") {
                updatedData.services[identifier].label.text = e.target.textContent;
            } else if (type == "servicePrice") {
                updatedData.services[identifier].price.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Services_theme_2_variant_1" }
        };

        const theme = websiteTheme.name === "theme_2" ? websiteTheme.content : themes.theme_2
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center`}>
                        <DropZone zone="services_theme_2_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>

                        <div style={{ backgroundColor: theme.colors.secondary }} className={`tw-h-[4px] tw-w-[60px] tw-rounded tw-mx-auto`}></div>


                        <DropZone zone="services_theme_2_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-5 tw-w-fit tw-mt-10 tw-mx-auto">
                        {data.services.map((service, i) =>
                            <div className="tw-w-[280px] tw-h-[275px] md:tw-w-[310px] md:tw-h-[300px] tw-relative tw-p-4">
                                <div style={{ outline: "0.5px solid white" }} className="tw-flex tw-flex-col tw-p-4 tw-justify-center tw-items-center tw-gap-5 tw-text-center tw-h-full tw-relative tw-z-10">
                                    <EditableText handleChange={(e) => handleChange(e, "serviceTitle", i)} data={service.label.typography}>
                                        {service.label.text}
                                    </EditableText>

                                    <div style={{ backgroundColor: theme.colors.secondary }} className={`tw-h-[4px] tw-w-[60px] tw-rounded tw-mx-auto`}></div>

                                    <EditableText handleChange={(e) => handleChange(e, "servicePrice", i)} data={service.price.typography}>
                                        {service.price.text}
                                    </EditableText>

                                    <BookButton label="Book" styles={{ backgroundColor: theme.colors.secondary }} classes="tw-py-2 tw-px-4 tw-font-semibold tw-text-lg" />
                                </div>

                                <div
                                    style={{
                                        backgroundImage: `url("${service.url}")`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        opacity: 0.15,
                                        zIndex: 5
                                    }}>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </Wrapper>
        );
    },
};

export const ServicesWrapper: React.FC<{
    children: React.ReactNode,
    showText: Boolean,
    showIcon: Boolean
}> = ({ children, showText, showIcon }) => {
    return <div className={`${showText ? "tw-px-5 tw-py-2 tw-w-full" : "tw-px-2 tw-py-1 tw-w-fit"} tw-border-transparent tw-rounded tw-shadow-md tw-flex tw-items-center tw-gap-4 tw-bg-background dark:tw-bg-backgroundDark tw-transition-all tw-duration-300 tw-ease-in-out`}>
        {showIcon &&
            (
                showText ?
                    <span>
                        <BsTrophyFill size={22} />
                    </span>
                    :
                    <Tooltip title="Services">
                        <span>
                            <BsTrophyFill size={18} />
                        </span>
                    </Tooltip>
            )
        }
        {showText &&
            <div className="text-sm">Services</div>
        }
    </div>;
};